export const FULL_DATE_FORMAT = 'DD.MM.YYYY';
export const FULL_DATE_TIME_FORMAT = 'DD.MM.yyyy, HH:mm';

export const SupportedCurrencies = ['EUR', 'USD'] as const;
export type SupportedCurrency = typeof SupportedCurrencies[number];

export const currencySymbol: { [key in SupportedCurrency]: string } = {
  EUR: '\u20AC',
  USD: '\u0024',
};

export const SessionStorageKeys = {
  codeVerifier: 'code_verifier',
  state: 'state',
  token: 'token',
  idToken: 'id_token',
  tokenRefreshingAttempts: 'token_refresh_attempts',
};

export const LocalStorageKeys = {
  selectedDeliveryPointId: 'selected_delivery_point_id',
  selectedMultiDeliveryPointIds: 'selected_multi-delivery_point_ids',
  ciamAuthRetries: 'ciam_auth_retries',
};

export const CertificateProducts = {
  renewableHeatProducts: [
    'Fortum Eko',
    'EkoPlus',
    'Fortum Rakentaja',
    'Fortum Mainio',
    'Fortum Kestolämpö',
  ],
  recoveredWasteHeatProducts: ['Fortum Eko5'],
};

export const SkarppiProducts = [
  'SKARPMIT',
  'SKARPOLO',
  'SKARPPI',
  'SKARPTER',
  'SKARPTOP',
];

export const HeathubProducts = ['30204982-2', '032023-2'];

export const NOTIFICATION_AUTO_CLOSE_DELAY_MS = 5000;

export const YEARS_AMOUNT_FOR_DOCUMENTS_FETCHING = 4;

export const TOKEN_REFRESHING_MAX_ATTEMPTS = 1;
export const CIAM_AUTH_MAX_RETRIES = 2;

export const DEFAULT_REQUEST_STALE_TIME = 1800000;
