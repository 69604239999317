import { useUserInfo } from '@api/user/useUserInfo';
import { FeedbackSurvey } from '@components/FeedbackSurvey';
import { LoginError } from '@components/LoginError';
import { mobileWidthPattern } from '@configs/breakpoints';
import {
  footerSocialIcons,
  footerData as footerStaticData,
} from '@configs/footer';
import {
  breakpoints,
  ContentText,
  Footer,
  IconInfo,
  Notification,
  spacing,
} from '@fortum/elemental-ui';
import { useTabsWithRouter } from '@utils/hooks/useTabsWithRouter';
import React, { Suspense, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { useResponsiveWidth } from '@utils/useResponsiveWidth';
import {
  MainContainer,
  NavigationTabsContainer,
  NotificationContainer,
  PositionedLoader,
  StyledTabs,
  TopContainer,
} from './App.styles';
import { AppHeader } from './components/AppHeader/AppHeader';
import {
  fallbackLng,
  isSupportedLanguage,
  Language,
  Namespace,
  supportedLanguages,
} from './configs/i18n';

export const AppMainLayout: React.FC = () => {
  const { t: glossaryT, i18n } = useTranslation<Namespace>('glossary');
  const responsiveWidth = useResponsiveWidth();
  const isLargeScreen = responsiveWidth > breakpoints.l;

  const [isNotificationClosed, setIsNotificationClosed] = useState(false);

  const language = useMemo(
    () => (isSupportedLanguage(i18n.language) ? i18n.language : fallbackLng),
    [i18n.language],
  );

  const footerData = useMemo(() => footerStaticData[language], [language]);

  const languagesMutableArray = [...supportedLanguages];

  const notificationText = useMemo(() => glossaryT('notification'), [language]);

  const onLanguageSelect = async (lang: string) => {
    const supportedLanguage = isSupportedLanguage(lang) ? lang : fallbackLng;

    await i18n.changeLanguage(supportedLanguage);
  };

  const userInfoQuery = useUserInfo();

  const { tabItems, onTabChange, activeTab } = useTabsWithRouter();

  const userName = useMemo(() => {
    if (!userInfoQuery.data) {
      return '';
    }

    const { firstName, lastName, customerType } = userInfoQuery.data;
    const customerTypeDescription = customerType ? ` (${customerType})` : '';

    return `${firstName} ${lastName}${customerTypeDescription}`;
  }, [userInfoQuery.data?.firstName, userInfoQuery.data?.lastName]);

  const { matches: isMobile } = matchMedia(mobileWidthPattern);

  const renderContent = () => {
    if (userInfoQuery.isLoading) {
      return <PositionedLoader />;
    }

    if (userInfoQuery.isError) {
      return <LoginError />;
    }

    if (userInfoQuery.data) {
      return (
        <>
          <NavigationTabsContainer>
            {isLargeScreen && (
              <StyledTabs
                textSize="L"
                items={tabItems}
                onTabChange={onTabChange}
                active={activeTab}
              />
            )}
          </NavigationTabsContainer>
          {!isNotificationClosed && notificationText !== '-' && (
            <Notification
              data-test="notification-error"
              status="alert"
              icon={IconInfo}
              mb={spacing.xxxs}
              maxHeight="auto"
              onClose={() => setIsNotificationClosed(true)}
            >
              <ContentText>{notificationText}</ContentText>
            </Notification>
          )}
          <Suspense fallback={<PositionedLoader />}>
            <Outlet />
          </Suspense>
        </>
      );
    }
    return null;
  };

  return (
    <MainContainer>
      <TopContainer>
        {isMobile && (
          <NotificationContainer>
            <Notification
              heading={glossaryT('reportTabs.notificationForMobileHeading')}
              iconButtonProps={{ 'aria-label': 'close notification' }}
              readMoreText={glossaryT('reportTabs.readMore')}
              icon={IconInfo}
            >
              <ContentText>
                {glossaryT('reportTabs.notificationForMobile')}
              </ContentText>
            </Notification>
          </NotificationContainer>
        )}
        <AppHeader
          text={glossaryT('app.name')}
          currentLanguage={i18n.language as Language}
          onLanguageSelect={onLanguageSelect}
          languages={languagesMutableArray}
          userName={userName}
          customerType={userInfoQuery.data?.customerType ?? null}
        />
        {renderContent()}
      </TopContainer>
      <FeedbackSurvey />
      <Footer
        data={footerData}
        language={language}
        key={language}
        socialIcons={footerSocialIcons}
      />
    </MainContainer>
  );
};
