import i18n, { Namespace } from '@configs/i18n';
import { colors, Heading } from '@fortum/elemental-ui';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Container,
  Description,
  DescriptionsContainer,
  DetailedDescriptionContainer,
} from './styles';

export const LoginError: React.FC = () => {
  const { t: commonT } = useTranslation<Namespace>('common');
  const detailedDescription = useCallback(
    () => (
      <DetailedDescriptionContainer>
        <Description size="l">
          {commonT('errorHandling.loginError.detailedDescription.phoneLabel')}
        </Description>
        <Description size="l" color={colors.ivyGreen600}>
          {commonT('errorHandling.loginError.detailedDescription.phoneNumber')}
        </Description>
        <Description size="l">
          {commonT('errorHandling.loginError.detailedDescription.middleText')}
        </Description>
        <Description size="l" color={colors.ivyGreen600}>
          {commonT('errorHandling.loginError.detailedDescription.contactEmail')}
        </Description>
      </DetailedDescriptionContainer>
    ),
    [i18n.language],
  );
  return (
    <Container>
      <Heading level={4}>{commonT('errorHandling.loginError.header')}</Heading>
      <DescriptionsContainer>
        <Description size="l">
          {commonT('errorHandling.loginError.description')}
        </Description>
        {detailedDescription()}
      </DescriptionsContainer>
    </Container>
  );
};
