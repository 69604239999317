import { useEffect, useState } from 'react';

export const useResponsiveWidth = (): number => {
  const [responsiveWidth, setResponsiveWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setResponsiveWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return responsiveWidth;
};
