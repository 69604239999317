import { breakpoints } from '@configs/breakpoints';
import {
  Box,
  colors,
  Loader,
  px2rem,
  Tabs,
  TabsProps,
} from '@fortum/elemental-ui';
import styled from 'styled-components';

export const MainContainer = styled.div`
  --max-content-width: ${px2rem(1280)};

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 70vh;
  position: relative;
`;

export const AppHeader = styled.div`
  display: flex;
  flex-flow: row nowrap;
`;

export const NavigationTabsContainer = styled.div`
  border-bottom: 1px solid ${colors.silverGrey};
  box-sizing: border-box;
  background-color: ${colors.snowWhite};
`;

export const StyledTabs = styled(Tabs)<TabsProps>`
  max-width: var(--max-content-width);
  margin: 0 auto;
`;

export const PositionedLoader = styled(Loader)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 50%);
`;

export const NotificationContainer = styled(Box)`
  @media screen and (max-width: ${breakpoints.mobile}) {
    margin-top: ${px2rem(78)};
  }
`;
